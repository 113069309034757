@import url('https://fonts.googleapis.com/css?family=Readex+Pro');


.App {
  display: flex;
  flex-direction: column;
  text-align: center;
  background: #faf4d8;
  color: #584330;
  min-height: 100vh;
  position: relative;
  font-family: 'Readex Pro';
  padding: 0 20%;
}

.App * {
  transition: all ease 0.5s;
}

.divider {
  width: 100%;
  border-top: dotted 5px;
  margin: 100px 0;
}

.Hamburger {
  position: absolute;
  top: 1em;
  right: 0.4rem;
  display: none;
  flex-direction: column;
  justify-content: space-between;
  width: 60px;
  height: 70px;
  cursor: pointer;
  transition: 0s;
}

.Hover {
  filter: brightness(0) 
  saturate(100%) invert(63%) 
  sepia(9%) saturate(2860%) 
  hue-rotate(326deg) brightness(90%) 
  contrast(99%);
}

#Navbar {
  height: 300px;
}

.VideoContainer {
  position: relative;
  display: flex;
  justify-content: center;
}

.VolumeContainer {
  position: absolute;
  bottom: 0.5%;
  right: 0.23%;
  height: 12%;
  background: #faf4d8;
  border: 10px solid #db725d;
  border-radius: 15px;
  max-width: 10%;

}

img {
  object-fit: contain;
}

h1 {
  font-size: 4em;
}
h2 {
  font-size: 3em;
}

h3 {
  font-size: 2em;
}

h4 {
  font-size: 1em;
}

@media (max-width: 1920px) {
  .App {
    padding: 0 10%;
  }
}

@media (max-width: 1200px) {
  .App {
    padding: 0;
  }
}

@media (max-width: 1000px) {
  #Navbar {
    height: 100px;
  }
  .Title {
    font-size: 3em;
  }
  .Logo{
    position: absolute;
    left: 0;
  }
  .Hamburger{
    display: flex;
  }
  .Links li{
    display: none;
  }
}

@media (max-width: 480px) {
  .Title {
    font-size: 2em;
    margin-top: 50px !important;
  }
  .Video {
    margin-top: 50px !important;
  }
  .VolumeContainer {
    border: 5px solid #db725d;;
  }
}